import React from 'react'
import { useLocation } from 'react-router'
import { qps_from_search } from '../misc'

const PageConfirmEmail = () => {
  const { search } = useLocation()
  const { token } = qps_from_search(search)

  const url = `${process.env.REACT_APP_MOBILE_BASE_URL}/email_confirm?token=${token}`

  React.useEffect(() => {
    if (process.env.REACT_APP_MOBILE_BASE_URL && document && document.location) {
      document.location.href = url
    }
  }, [url])

  return (
    <header style={{ height: '100%', minHeight: '100vh' }}>
      <div className="layer" style={{ height: '100%', minHeight: '100vh' }}>
        <div className="container">
          <div className="headerlogo">
            <img src="images/logo.svg" alt="Logo Mardi Soir Je Sors"></img>
          </div>

          <h1>Confirmation de l'e-mail</h1>

          <p>
            Confirme en cliquant sur{' '}
            <a style={{ color: 'white' }} href={url}>
              ce lien
            </a>
          </p>

          <p>
            Le lien marche uniquement depuis le téléphone sur lequel tu as installé l'application
          </p>

          {/* <div className="stores">
            <div className="appstore">
              <a href="https://www.apple.com/fr/ios/app-store/" target="_blank">
                <img src="images/appstore.svg" alt="Téléchargez dans l'App Store"></img>
              </a>
            </div>

            <div className="googleplay">
              <a href="https://play.google.com/" target="_blank">
                <img src="images/googleplay.svg" alt="Disponible sur Google Play"></img>
              </a>
            </div>
          </div> */}
        </div>
      </div>{' '}
      {/* closing layer */}
    </header>
  )
}

export default PageConfirmEmail
