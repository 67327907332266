import './App.css'

import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import PageConfirmEmail from './Page/ConfirmEmail'
import PageHome from './Page/Home'
import PageParrain from './Page/Parrain'
import PagePasswordRecovery from './Page/PasswordRecovery'
import PageCgu from './Page/Cgu'
import PagePolicy from './Page/Policy'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/email-confirm" component={PageConfirmEmail} />
        <Route path="/parrain" component={PageParrain} />
        <Route path="/change-password" component={PagePasswordRecovery} />
        <Route path="/cgu" component={PageCgu} />
        <Route path="/policy" component={PagePolicy} />
        <Route component={PageHome} />
      </Switch>
    </Router>
  )
}

export default App
