import React from 'react'
import { useLocation } from 'react-router'
import jwt_decode from 'jwt-decode'
import { useFetchApi } from '../hook'
import { qps_from_search } from '../misc'

const PageParrain = () => {
  const [email, set_email] = React.useState('')
  const [submit_state, set_submit_state] = React.useState(null)
  const { search } = useLocation()
  const { token } = qps_from_search(search)

  React.useEffect(() => {
    window.$crisp.push(['do', 'chat:hide'])
  })

  const fetcher = useFetchApi()

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault()
      set_submit_state('submitting')

      try {
        await fetcher('/user/by_referral', {
          method: 'POST',
          body: JSON.stringify({ email, token })
        })
        set_submit_state('submitted')
      } catch (err) {
        window.alert('Email deja existant ou mauvais format')
        set_submit_state(null)
      }
    },
    [email, fetcher, token]
  )

  return (
    <>
      <header style={{ height: '100%' }}>
        <div className="layer" style={{ height: '100%' }}>
          <div className="container">
            <div className="headerlogo">
              <img src="images/logo.svg" alt="Logo Mardi Soir Je Sors"></img>
            </div>

            <h1>Parrain {jwt_decode(token).ref_name}</h1>

            {submit_state === 'submitted' ? (
              <button
                style={{
                  width: '100%',
                  border: 'none',
                  padding: 20,
                  background: '#FFF06C',
                  color: '#495057',
                  fontWeight: 700,
                  marginTop: 50,
                  opacity: submit_state === 'submitting' ? 0.4 : 1
                }}
                onClick={() => {
                  set_email('')
                  set_submit_state(null)
                }}
              >
                OK ! Recommencer
              </button>
            ) : (
              <form onSubmit={onSubmit}>
                <input
                  type="email"
                  style={{ padding: 10, marginTop: 50, width: '100%', fontSize: 19 }}
                  placeholder="email"
                  required
                  value={email}
                  onChange={(e) => set_email(e.target.value)}
                />

                <div style={{ marginTop: 30 }}>
                  <input
                    disabled={submit_state !== null}
                    type="submit"
                    style={{
                      width: '100%',
                      border: 'none',
                      padding: 20,
                      background: '#FFF06C',
                      color: '#495057',
                      fontWeight: 700,
                      opacity: submit_state === 'submitting' ? 0.4 : 1
                    }}
                    value="Inscrire"
                  />
                </div>
              </form>
            )}
          </div>
        </div>
      </header>
    </>
  )
}

export default PageParrain
