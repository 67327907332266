import React from 'react'

const PagePolicy = () => {
  return (
    <div>
      <header>
        <div className="layer">
          <div className="container">
            <div className="headerlogo">
              <img src="images/logo.svg" alt="Logo Mardi Soir Je Sors"></img>
            </div>

            <h1>POLITIQUE DE CONFIDENTIALITÉ</h1>
          </div>
        </div>{' '}
        {/* closing layer */}
      </header>
      <main>
        <div className="container">
          <p>
            <strong>ARTICLE 1 : PROTECTION DES DONNÉES PERSONNELLES</strong>
          </p>
          <p>1.1 Responsable De Traitement - Destinataires</p>
          <p>
            Des Données Les données collectées sont traitées par : La société Devanco SARL au
            capital de 150 000,00 € immatriculée sous le numéro 537862013 au RCS de TOULOUSE, qui
            est propriétaire du service MardiSoirJeSors, dont le siège social est situé au 93 AV DES
            MINIMES 31200 TOULOUSE et représentée par son Président, Monsieur Julien NOLEAU. Seuls
            certains salariés des services client et technique et responsables habilités de Devanco
            traitent les données personnelles des Membres pour les finalités décrites à l'article
            1.2 ci-après. Ces salariés et responsables habilités n'ont accès qu'aux seules données
            qui leurs sont nécessaires dans le cadre de leurs fonctions. Devanco peut également
            contracter avec des sous-traitants authentifiés et fiables qui sont susceptibles
            d'accéder, héberger et/ou traiter certaines données personnelles des Membres pour le
            compte de Devanco, selon ses instructions, dans le respect de la Politique de
            confidentialité et qui garantissent la sécurité et la confidentialité des données
            personnelles des Membres. Ces sous-traitants permettent notamment à Devanco d'établir
            des statistiques sur les volumes de fréquentation et/ou l'utilisation de l'Application
            et/ou assurer le bon fonctionnement de l'Application. Les données personnelles d'un
            Membre identifiées comme « accessibles au public de l'Application » à l'article 1.3
            ci-après qui figurent sur son profil ne sont accessibles qu'aux autres Membres de
            l'Application et qui ont été mis en relation via les services de l'application.
          </p>
          <p>1.2 Finalités Des Traitements Opérés Par Devanco</p>
          <p>
            Les données collectées par Devanco sont nécessaires au bon fonctionnement et à
            l'amélioration des Services de l'Application qui sont proposés aux Membres. Les données
            collectées permettent également à Devanco de réaliser des études statistiques et
            analyses de type marketing en rapport avec l'utilisation de l'Application et des
            Services par les Membres. Devanco peut enfin accéder aux données personnelles des
            Membres, les conserver et les partager avec des tiers habilités en réponse à une demande
            légale (mandat de perquisition, ordonnance d'un tribunal ou autre) ou pour se conformer
            à des obligations légales, réglementaires, judiciaires ou administratives ; déceler ou
            prévenir des activités frauduleuses ou des atteintes à la sécurité des Services, en
            application et dans le respect de la loi.
          </p>
          <p>1.3 Collecte Loyale Des Données Personnelles</p>
          <p>
            Type De Données Données relatives à l'identité : Le formulaire d'inscription est
            obligatoire pour accéder aux Services. L'inscription impose au Membre de communiquer
            certaines de ses données à caractère personnel :
            <ul>
              <li>
                Photo (accessible au public de l'Application): Une photo de profil publique. Le
                Membre aura ensuite la possibilité de modifier cette photo . Dans un souci de
                transparence, les photos publiées doivent représenter le Membre et ne doivent pas
                tromper les autres Membres sur son apparence. Ces photos ne doivent ni représenter
                une célébrité, ni inclure le portrait d'un mineur, ni incarner un message raciste,
                choquant, illégal ou sexuel, ni contenir des informations personnelles. Elle est
                publiée dans l'Application sous la seule responsabilité du Membre. En devenant
                Membre, ce dernier accepte la publication de sa photo dans l'Application.
              </li>
              <li>
                sa date de naissance (seul l'âge déduit de la date de naissance est accessible sur
                l'Application)
              </li>
              <li>son sexe (accessible au public de l'Application)</li>
              <li>son prénom (accessible au public de l'Application)</li>
              <li>son adresse e-mail (inaccessible pour le public de Devanco)</li>
            </ul>{' '}
            Données relatives aux communications échangées : Les messages sont envoyés et stockés
            sur les serveurs de Devanco (prestataire OVH) exclusivement pour assurer le Service de
            transmission des échanges entre les Membres qui ont été mis en relation. Les Messages
            sont strictement privés et n'engagent que les Membres intéressés. Les responsables
            habilités de Devanco n'exercent aucun droit de regard sur ces conversations, sauf sur
            requête judiciaire et/ou conformément aux dispositions législatives ou règlementaires
            applicables et/ou dans le cadre des éléments de preuve éventuellement fournis à Devanco
            par un Membre à l'occasion d'un signalement. Par ailleurs, les Messages strictement
            privés ne seront accessibles ni aux autres Membres non concernés par l'échange, ni à des
            tiers quelconques. Données relatives au mobile : Lorsque le Membre utilise
            l'Application, Devanco collecte des journaux serveurs qui peuvent inclure des
            informations telles que les adresses IP, le système d'exploitation ou les pannes
            d'application. Devanco n'est pas en mesure d'accéder à l'historique de navigation de ses
            Membres.
          </p>
          <p>1.4 Précisions Complémentaires Sur Les Données Collectées</p>
          <p>
            Les données personnelles que le Membre a publiées dans l'Application sont rendues
            strictement invisibles par Devanco pour les internautes non-inscrits et les tiers. La
            responsabilité de Devanco ne saurait être recherchée pour les données publiées sur
            Devanco et divulguées par un Membre.
          </p>
          <p>1.5 Mise En Garde Des Membres Sur Les Données Sensibles</p>
          <p>
            Certaines données sont qualifiées juridiquement de « sensibles » en vertu de l'article 8
            de la loi n° 78-17 Informatique et Libertés du 6 janvier 1978. Lors de son inscription
            sur l'Application, le Membre peut faire le choix de communiquer certaines données
            sensibles le concernant à l'occasion des mesages échangés telles que, par exemple, son
            origine ethnique, son orientation sexuelle ou ses opinions politiques. Devanco
            n'encourage pas le Membre à communiquer des données sensibles aux autres Membres ou à
            Devanco via l'Application. Si malgré tout, le Membre tient à communiquer de telles
            données sur son profil, le Membre devra l'écrire lui-même sur la section « bio ». Cette
            communication vaut consentement exprès du Membre à la collecte et au traitement de ces
            données par Devanco.
          </p>
          <p>1.6 Mention Des Droits Du Membre</p>
          <p>
            Conformément à la loi n° 78-17 du 6 janvier 1978, dite Loi Informatique et Libertés,
            chaque Membre dispose d'un droit d'accès, de rectification et d'opposition et de
            suppression sur ses données personnelles. Soucieux des préoccupations de ses Membres,
            Devanco s'engage à respecter la protection des données personnelles et à traiter ces
            demandes dans les meilleurs délais. Le Membre peut exercer ses droits, sous réserve de
            prouver son identité, en envoyant un courrier ou un email à l'adresse mentionnée à
            l'article 17 des présentes CGU. •Le droit d'accès autorise le Membre à interroger
            Devanco en vue d'obtenir la communication des données le concernant sous une forme
            accessible sur le fondement de l'article 39 de la Loi Informatique et Libertés. •Le
            droit de rectification confère au Membre le droit d'exiger de Devanco que soient
            rectifiées, complétées, mises à jour ou effacées les données à caractère personnel le
            concernant qui sont inexactes, incomplètes, équivoques, périmées ou dont l'utilisation,
            la communication ou la conservation est interdite sur le fondement de l'article 40 de la
            Loi Informatiques et Libertés ; •Le droit d'opposition confère au Membre le droit de
            s'opposer sans frais et de manière discrétionnaire, à ce que ses données soient
            utilisées par Devanco à des fins de prospection, notamment commerciale, sur le fondement
            de l'article 38 de la Loi Informatique et Libertés. •Le droit de suppression confère au
            Membre le droit d'exiger l'effacement sans frais de manière discrétionnaire les données
            communiquées à Devanco, dans la limite toutefois des obligations légales qui incombent à
            Devanco en sa qualité d'hébergeur. Le Membre peut également procéder de lui-même à la
            rectification de la plupart des données le concernant sur l'Application elle-même.
          </p>
          <p>1.7 Absence de cession de vos données personnelles</p>
          <p>
            Les données personnelles, visibles ou non pour les autres Membres, ne sont pas cédées
            par Devanco auprès de tiers.
          </p>
          <p>1.8 Conservation des données</p>
          <p>
            Devanco conserve les données du Membre pendant toute la durée nécessaire à l'utilisation
            du Compte du Membre. Par ailleurs, il convient de rappeler que Devanco conformément à
            son statut d'hébergeur est tenu d'une obligation légale de conserver certaines données à
            caractère personnel des Membres pendant une durée d'un an sur le fondement de l'article
            6, II de la Loi pour la Confiance dans l'Economie Numérique du 21 juin 2004. Au regard
            du décret du 25 février 2011 relatif à la conservation et à la communication des
            données, le délai de conservation imposé à Devanco court à compter de la création des
            contenus pour les données mentionnées aux 1° et 2° de l'article 7, II de la Loi pour la
            Confiance dans l'Economie Numérique, soit : • L'identifiant de la connexion à l'origine
            de la communication ; • L'identifiant attribué par le système d'information au contenu ;
            • Les types de protocoles utilisés ; • La nature de l'opération ; • Les dates et heures
            de l'opération ; • L'identifiant utilisé par l'auteur de l'opération lorsque celui-ci
            l'a fourni. Le délai de conservation imposé à Devanco court à compter de la résiliation
            du Compte ou de la suppression du profil pour les données mentionnées aux 3° du même
            article, soit : • Les prénoms • Les pseudonymes utilisés • Les adresses de courrier
            électronique ou de Compte associées • Le numéro de téléphone • Le mot de passe ainsi que
            les données permettant de le vérifier ou de le modifier, dans leur dernière version mise
            à jour Devanco s'engage à fournir les meilleurs efforts et tous les moyens en sa
            possession pour garantir la sécurité optimale des données conservées. Il appartient tout
            de même au Membre de prendre les mesures appropriées pour protéger ses données. Devanco
            refuse de collecter les données d'enfants mineurs. En cas de mensonge d'un Membre
            concernant sa date de naissance et plus particulièrement en cas d'attestation
            frauduleuse sur sa majorité, les parents de l'enfant mineur sont invités à avertir
            Devanco en envoyant un courriel à l'adresse indiquée à l'article 15, afin de demander
            l'effacement des données. Devanco s'engage à supprimer l'ensemble des données
            personnelles concernant l'enfant mineur dans les plus brefs délais.
          </p>
          <p>1.9 Transfert de données hors UE</p>
          <p>
            Lors de son inscription, le Membre consent expressément à ce que ses données puissent
            être transmises à des prestataires sous-traitants de Devanco situés hors Union
            européenne, aux fins de réalisation d'études statistiques et, le cas échéant,
            d'hébergement des données traitées par l'Application. Devanco garantit que ces
            transferts sont effectués dans des conditions permettant d'assurer la confidentialité et
            la sécurité des données et un niveau de protection adéquat dans le respect des
            dispositions de l'article 68 et 69 de la loi Informatique et Liberté.
          </p>
        </div>
      </main>
    </div>
  )
}

export default PagePolicy
