import React from 'react'

const PageCgu = () => {
  return (
    <div>
      <header>
        <div className="layer">
          <div className="container">
            <div className="headerlogo">
              <img src="images/logo.svg" alt="Logo Mardi Soir Je Sors"></img>
            </div>

            <h1>CONDITIONS GÉNÉRALES D'UTILISATION </h1>
          </div>
        </div>{' '}
        {/* closing layer */}
      </header>
      <main>
        <div className="container">
          <p>
            <strong>DÉFINITIONS DES TERMES</strong>
          </p>
          <p>
            Application: l'application MardiSoirJeSors, disponible sur l'App Store d'Apple et dans
            le Play Store de Google.
          </p>
          <p>
            Devanco: Devanco, SARL au capital de 150 000,00 € euros, enregistrée au RCS Toulouse
            sous le matricule 537862013, propriétaire et seule exploitante de l'Application
            MardiSoirJeSors
          </p>
          <p>Site: Le présent site web.</p>
          <p>CGU: Les présentes Conditions Générales d'Utilisation.</p>
          <p>Membre: Personne inscrite sur l'Application.</p>
          <p>
            Services: Ensemble des services, payants ou non, offerts par MardiSoirJeSors pour
            favoriser la rencontre virtuelle ou réelle entre les Membres.
          </p>
          <p>
            Messages chat: Seuls les Membres qui ont été mis en relation via L'Application peuvent
            faire l'action « Discuter » dans l'Application, et s'envoyer des Messages.
          </p>
          <p>
            Soirées: L'Application propose des soirées en mettant en relation plusieurs membre afin
            de leur suggérer une rencontre réelle dans un lieu public
          </p>
          <p>
            MardiSoirJeSors: MardiSoirJeSors désignera par la suite l'Application ou un des autres
            services.
          </p>
          <p>
            Compte: Le compte est l'espace personnel dédié au Membre sur l'Application, auquel il
            accède en s'inscrivant et en se connectant sur l'Application. Il permet au Membre
            d'accéder aux Services.
          </p>
          <p>
            <strong>ARTICLE 1 – CHAMP D'APPLICATION</strong>
          </p>
          Les CGU définissent les droits et obligations des parties dans le cadre de l'utilisation
          de l'Application. Elles sont le support d'une relation contractuelle entre le Membre et la
          société Devanco.
          <p>1.1 Conflit De Normes</p>
          Les présentes conditions générales d'utilisation (CGU) définissent les principales règles
          d'utilisation de l'Application. Elles sont complétées par la Politique de confidentialité.
          Dans l'hypothèse d'un conflit entre les différentes politiques ayant pour conséquence un
          manque d'accessibilité, d'intelligibilité et /ou de prévisibilité des règles, les
          présentes CGU priment.
          <p>1.2 Autonomie Des Stipulations</p>
          Dans l'hypothèse où l'une des stipulations des présentes CGU serait déclarée nulle ou
          inapplicable par l'effet d'une loi d'un règlement ou d'une décision juridictionnelle
          émanant d'un tribunal compétent, les autres stipulations conserveront leur validité et
          leur force, sauf à imaginer que les présentes CGU soient dénaturées ou que cela entraine
          un déséquilibre significatif des obligations à la charge des parties.
          <p>
            <strong>ARTICLE 2 – MODIFICATION DES CGU</strong>
          </p>
          <p>2.1 Mise À Jour</p>
          Devanco se réserve le droit, à tout moment et de manière unilatérale, de compléter ou de
          modifier les présentes CGU de la façon qu'elle juge nécessaire, ou de mettre fin aux
          Services. L'Utilisateur sera invité à prendre connaissance des CGU ainsi modifiées. Il
          incombe au Membre de lire attentivement les CGU lors de leur mise à jour et de prendre
          pleinement connaissance des modifications engendrées par cette dernière. Le fait que le
          Membre continue à utiliser les Services de l'Application après la mise à jour des CGU vaut
          acceptation sans réserve de sa part aux modifications apportées aux CGU. En cas de
          désaccord avec les modifications des CGU, le Membre a la faculté de mettre fin aux
          Services et de résilier son Compte conformément à l'article 14.
          <p>2.2 Application Immédiate</p>
          Les nouvelles CGU s'appliqueront immédiatement pour les Membres.
          <p>
            <strong>ARTICLE 3 : JURIDICTIONS COMPÉTENTES ET LOI APPLICABLE</strong>
          </p>
          <p>3.1 Loi Applicable</p>
          <p>Les présentes CGU sont soumises à la loi française.</p>
          <p>3.2 Juridictions Compétentes</p>
          <p>
            Le Membre s'adressera en priorité aux équipes internes de Devanco pour tenter de
            résoudre amiablement son litige avec Devanco. Toute contestation ou litige relatif à la
            formation, l'interprétation, l'exécution ou la fin des présentes ou en relation avec les
            présentes CGU qui n'aurait pu être résolu amiablement sera soumis aux Tribunaux
            compétents de Toulouse.
          </p>
          <p>
            <strong>ARTICLE 4 : MENTIONS LEGALES</strong>
          </p>
          <p>
            Le Site est hébergé par la société Netlify, Inc., located at 2325 3rd Street, Suite 296,
            San Francisco, California 94107. Information de contact : support@netlify.com
          </p>
          <p>
            Le reste des services sont hébergées par OVH – 2 rue Kellermann – 59100 Roubaix –
            France. Téléphone : 08 203 203 63
          </p>
          <p>
            <strong>ARTICLE 5 : INSCRIPTION</strong>
          </p>
          <p>5.1 Modalités De Téléchargement</p>
          L'Application est disponible sur les plateformes de téléchargement légal de Apple, et sur
          Google (play store). Elle est téléchargeable gratuitement sur l'App Store d'Apple, sur le
          Play Store de Google. Devanco fonctionnera sur des iPhones utilisant une version de iOS
          compatible, et des Smartphones Android utilisant une version de Android compatible. Les
          compatibilités des versions peuvent évoluer au cours du temps
          <p>5.2 Droit Du Membre À L'inscription</p>
          <p>
            Lorsque les conditions nécessaires au statut de Membre sont remplies, Devanco attribue
            au Membre, à titre personnel, un Compte.
          </p>
          <p>5.3 Obligations Du Membre À L'inscription</p>
          <p>
            Le Membre doit impérativement avoir plus de 18 ans et garantit que toutes les
            informations qu'il communique lors de son inscription sont véridiques et à jour.
            L'inscription s'effectue via son adresse email. Ensuite lui sera demandé ses
            informations personnelles détaillées dans l'article 7.3. Lors de l'inscription, le
            Membre consent aux présentes CGU et à la Politique de confidentialité et s'engage à
            respecter les obligations à sa charge, notamment celles mentionnées dans le Code de
            conduite du Membre définit à l'article 9 des présentes CGU.
          </p>
          <p>
            <strong>ARTICLE 6 : SERVICES ET FONCTIONNALITÉS</strong>
          </p>
          <p>
            L'Application fournit aux Membres un service favorisant la rencontre amicale entre
            membres au travers de soirées mettant en relation plusieurs membres et en leur suggérant
            une rencontre réelle dans un lieu public. Chaque Membre est libre de discuter et nouer
            des relations avec d'autres Membres dans le respect des présentes CGU. En aucun cas les
            services proposés par Devanco ne peuvent être assimilés à des activités de conseil ou de
            courtage matrimonial. Ils visent uniquement à faciliter la rencontre par le biais de
            l'Application , de manière virtuelle ou réelle. L'intégralité des services fournies par
            l'Application est gratuit.
          </p>
          <p>
            <strong>ARTICLE 7 : PROTECTION DES DONNÉES PERSONNELLES</strong>
          </p>
          <p>7.1 Responsable De Traitement - Destinataires</p>
          <p>
            Des Données Les données collectées sont traitées par : La société Devanco SARL au
            capital de 150 000,00 € immatriculée sous le numéro 537862013 au RCS de TOULOUSE, qui
            est propriétaire du service MardiSoirJeSors, dont le siège social est situé au 93 AV DES
            MINIMES 31200 TOULOUSE et représentée par son Président, Monsieur Julien NOLEAU. Seuls
            certains salariés des services client et technique et responsables habilités de Devanco
            traitent les données personnelles des Membres pour les finalités décrites à l'article
            7.2 ci-après. Ces salariés et responsables habilités n'ont accès qu'aux seules données
            qui leurs sont nécessaires dans le cadre de leurs fonctions. Devanco peut également
            contracter avec des sous-traitants authentifiés et fiables qui sont susceptibles
            d'accéder, héberger et/ou traiter certaines données personnelles des Membres pour le
            compte de Devanco, selon ses instructions, dans le respect de la Politique de
            confidentialité et qui garantissent la sécurité et la confidentialité des données
            personnelles des Membres. Ces sous-traitants permettent notamment à Devanco d'établir
            des statistiques sur les volumes de fréquentation et/ou l'utilisation de l'Application
            et/ou assurer le bon fonctionnement de l'Application. Les données personnelles d'un
            Membre identifiées comme « accessibles au public de l'Application » à l'article 7.3
            ci-après qui figurent sur son profil ne sont accessibles qu'aux autres Membres de
            l'Application et qui ont été mis en relation via les services de l'application.
          </p>
          <p>7.2 Finalités Des Traitements Opérés Par Devanco</p>
          <p>
            Les données collectées par Devanco sont nécessaires au bon fonctionnement et à
            l'amélioration des Services de l'Application qui sont proposés aux Membres. Les données
            collectées permettent également à Devanco de réaliser des études statistiques et
            analyses de type marketing en rapport avec l'utilisation de l'Application et des
            Services par les Membres. Devanco peut enfin accéder aux données personnelles des
            Membres, les conserver et les partager avec des tiers habilités en réponse à une demande
            légale (mandat de perquisition, ordonnance d'un tribunal ou autre) ou pour se conformer
            à des obligations légales, réglementaires, judiciaires ou administratives ; déceler ou
            prévenir des activités frauduleuses ou des atteintes à la sécurité des Services, en
            application et dans le respect de la loi.
          </p>
          <p>7.3 Collecte Loyale Des Données Personnelles</p>
          <p>
            Type De Données Données relatives à l'identité : Le formulaire d'inscription est
            obligatoire pour accéder aux Services. L'inscription impose au Membre de communiquer
            certaines de ses données à caractère personnel :
            <ul>
              <li>
                Photo (accessible au public de l'Application): Une photo de profil publique. Le
                Membre aura ensuite la possibilité de modifier cette photo . Dans un souci de
                transparence, les photos publiées doivent représenter le Membre et ne doivent pas
                tromper les autres Membres sur son apparence. Ces photos ne doivent ni représenter
                une célébrité, ni inclure le portrait d'un mineur, ni incarner un message raciste,
                choquant, illégal ou sexuel, ni contenir des informations personnelles. Elle est
                publiée dans l'Application sous la seule responsabilité du Membre. En devenant
                Membre, ce dernier accepte la publication de sa photo dans l'Application.
              </li>
              <li>
                sa date de naissance (seul l'âge déduit de la date de naissance est accessible sur
                l'Application)
              </li>
              <li>son sexe (accessible au public de l'Application)</li>
              <li>son prénom (accessible au public de l'Application)</li>
              <li>son adresse e-mail (inaccessible pour le public de Devanco)</li>
            </ul>{' '}
            Données relatives aux communications échangées : Les messages sont envoyés et stockés
            sur les serveurs de Devanco (prestataire OVH) exclusivement pour assurer le Service de
            transmission des échanges entre les Membres qui ont été mis en relation. Les Messages
            sont strictement privés et n'engagent que les Membres intéressés. Les responsables
            habilités de Devanco n'exercent aucun droit de regard sur ces conversations, sauf sur
            requête judiciaire et/ou conformément aux dispositions législatives ou règlementaires
            applicables et/ou dans le cadre des éléments de preuve éventuellement fournis à Devanco
            par un Membre à l'occasion d'un signalement. Par ailleurs, les Messages strictement
            privés ne seront accessibles ni aux autres Membres non concernés par l'échange, ni à des
            tiers quelconques. Données relatives au mobile : Lorsque le Membre utilise
            l'Application, Devanco collecte des journaux serveurs qui peuvent inclure des
            informations telles que les adresses IP, le système d'exploitation ou les pannes
            d'application. Devanco n'est pas en mesure d'accéder à l'historique de navigation de ses
            Membres.
          </p>
          <p>7.4 Précisions Complémentaires Sur Les Données Collectées</p>
          <p>
            Les données personnelles que le Membre a publiées dans l'Application sont rendues
            strictement invisibles par Devanco pour les internautes non-inscrits et les tiers. La
            responsabilité de Devanco ne saurait être recherchée pour les données publiées sur
            Devanco et divulguées par un Membre.
          </p>
          <p>7.5 Mise En Garde Des Membres Sur Les Données Sensibles</p>
          <p>
            Certaines données sont qualifiées juridiquement de « sensibles » en vertu de l'article 8
            de la loi n° 78-17 Informatique et Libertés du 6 janvier 1978. Lors de son inscription
            sur l'Application, le Membre peut faire le choix de communiquer certaines données
            sensibles le concernant à l'occasion des mesages échangés telles que, par exemple, son
            origine ethnique, son orientation sexuelle ou ses opinions politiques. Devanco
            n'encourage pas le Membre à communiquer des données sensibles aux autres Membres ou à
            Devanco via l'Application. Si malgré tout, le Membre tient à communiquer de telles
            données sur son profil, le Membre devra l'écrire lui-même sur la section « bio ». Cette
            communication vaut consentement exprès du Membre à la collecte et au traitement de ces
            données par Devanco.
          </p>
          <p>7.6 Mention Des Droits Du Membre</p>
          <p>
            Conformément à la loi n° 78-17 du 6 janvier 1978, dite Loi Informatique et Libertés,
            chaque Membre dispose d'un droit d'accès, de rectification et d'opposition et de
            suppression sur ses données personnelles. Soucieux des préoccupations de ses Membres,
            Devanco s'engage à respecter la protection des données personnelles et à traiter ces
            demandes dans les meilleurs délais. Le Membre peut exercer ses droits, sous réserve de
            prouver son identité, en envoyant un courrier ou un email à l'adresse mentionnée à
            l'article 17 des présentes CGU. •Le droit d'accès autorise le Membre à interroger
            Devanco en vue d'obtenir la communication des données le concernant sous une forme
            accessible sur le fondement de l'article 39 de la Loi Informatique et Libertés. •Le
            droit de rectification confère au Membre le droit d'exiger de Devanco que soient
            rectifiées, complétées, mises à jour ou effacées les données à caractère personnel le
            concernant qui sont inexactes, incomplètes, équivoques, périmées ou dont l'utilisation,
            la communication ou la conservation est interdite sur le fondement de l'article 40 de la
            Loi Informatiques et Libertés ; •Le droit d'opposition confère au Membre le droit de
            s'opposer sans frais et de manière discrétionnaire, à ce que ses données soient
            utilisées par Devanco à des fins de prospection, notamment commerciale, sur le fondement
            de l'article 38 de la Loi Informatique et Libertés. •Le droit de suppression confère au
            Membre le droit d'exiger l'effacement sans frais de manière discrétionnaire les données
            communiquées à Devanco, dans la limite toutefois des obligations légales qui incombent à
            Devanco en sa qualité d'hébergeur. Le Membre peut également procéder de lui-même à la
            rectification de la plupart des données le concernant sur l'Application elle-même.
          </p>
          <p>7.7 Absence de cession de vos données personnelles</p>
          <p>
            Les données personnelles, visibles ou non pour les autres Membres, ne sont pas cédées
            par Devanco auprès de tiers.
          </p>
          <p>7.8 Conservation des données</p>
          <p>
            Devanco conserve les données du Membre pendant toute la durée nécessaire à l'utilisation
            du Compte du Membre. Par ailleurs, il convient de rappeler que Devanco conformément à
            son statut d'hébergeur est tenu d'une obligation légale de conserver certaines données à
            caractère personnel des Membres pendant une durée d'un an sur le fondement de l'article
            6, II de la Loi pour la Confiance dans l'Economie Numérique du 21 juin 2004. Au regard
            du décret du 25 février 2011 relatif à la conservation et à la communication des
            données, le délai de conservation imposé à Devanco court à compter de la création des
            contenus pour les données mentionnées aux 1° et 2° de l'article 7, II de la Loi pour la
            Confiance dans l'Economie Numérique, soit : • L'identifiant de la connexion à l'origine
            de la communication ; • L'identifiant attribué par le système d'information au contenu ;
            • Les types de protocoles utilisés ; • La nature de l'opération ; • Les dates et heures
            de l'opération ; • L'identifiant utilisé par l'auteur de l'opération lorsque celui-ci
            l'a fourni. Le délai de conservation imposé à Devanco court à compter de la résiliation
            du Compte ou de la suppression du profil pour les données mentionnées aux 3° du même
            article, soit : • Les prénoms • Les pseudonymes utilisés • Les adresses de courrier
            électronique ou de Compte associées • Le numéro de téléphone • Le mot de passe ainsi que
            les données permettant de le vérifier ou de le modifier, dans leur dernière version mise
            à jour Devanco s'engage à fournir les meilleurs efforts et tous les moyens en sa
            possession pour garantir la sécurité optimale des données conservées. Il appartient tout
            de même au Membre de prendre les mesures appropriées pour protéger ses données. Devanco
            refuse de collecter les données d'enfants mineurs. En cas de mensonge d'un Membre
            concernant sa date de naissance et plus particulièrement en cas d'attestation
            frauduleuse sur sa majorité, les parents de l'enfant mineur sont invités à avertir
            Devanco en envoyant un courriel à l'adresse indiquée à l'article 15, afin de demander
            l'effacement des données. Devanco s'engage à supprimer l'ensemble des données
            personnelles concernant l'enfant mineur dans les plus brefs délais.
          </p>
          <p>7.9 Transfert de données hors UE</p>
          <p>
            Lors de son inscription, le Membre consent expressément à ce que ses données puissent
            être transmises à des prestataires sous-traitants de Devanco situés hors Union
            européenne, aux fins de réalisation d'études statistiques et, le cas échéant,
            d'hébergement des données traitées par l'Application. Devanco garantit que ces
            transferts sont effectués dans des conditions permettant d'assurer la confidentialité et
            la sécurité des données et un niveau de protection adéquat dans le respect des
            dispositions de l'article 68 et 69 de la loi Informatique et Liberté.
          </p>
          <p>
            <strong>ARTICLE 8 : OBLIGATIONS ET RESPONSABILITÉ DE Devanco</strong>
          </p>
          <p>8.1 Moyens Techniques Et Sécurité</p>
          <p>
            Devanco s'oblige à fournir l'infrastructure et les moyens techniques requis pour la
            fourniture d'un Service de qualité aux Membres équipés d'un terminal iPhone compatible,
            des Smartphones Android compatibles. Devanco ne saurait être tenu responsable de tout
            défaut de fonctionnement du terminal du Membre ou de problème de compatibilité entre
            l'Application et le terminal. Toutes les mesures sont prises pour minimiser le risque
            d'accès frauduleux dans le système de Devanco. A cet égard, Devanco se sert de pare-feu
            soit d'équipements situés entre le réseau Internet et le réseau privé d'une entreprise
            pour accroître la sécurité de ce dernier en filtrant le trafic en provenance ou à
            destination d'Internet. Néanmoins il est impossible de garantir de manière absolue la
            sécurité d'un réseau. Devanco n'est pas responsable des pannes, interruptions ou du
            mauvais fonctionnement des Services du fournisseur d'accès à internet du Membre, ainsi
            que toute cause extérieure à Devanco qui pourrait interrompre ou endommager l'accès aux
            Services Devanco. Le Membre doit s'assurer de disposer d'une adresse de messagerie
            sécurisée. En dépit ou suite à l'omission de ces précautions, le Compte du Membre est
            susceptible, dans des cas demeurant rares, d'être victime de fraude. Devanco souhaite
            également prévenir le Membre contre tout risque exceptionnel de réception de Messages
            indésirables ou de Messages émanant prétendument de Devanco. Le Membre doit être
            attentif et vigilant afin, si ces hypothèses se réalisent, d'avertir Devanco en envoyant
            un courrier ou un email à l'adresse communiquée à l'article 15 des présentes.
          </p>
          <p>8.2 Comportements Illégaux</p>
          <p>
            Devanco et ses partenaires ne pourront être tenus responsables d'actions illégales ou
            passibles de sanctions pénales, commises par ses Membres, parmi lesquelles et de manière
            non exhaustive : Tout type d'escroquerie, La prostitution, L'usurpation d'identité,
            L'atteinte au droit de la propriété intellectuelle d'un tiers.
          </p>
          <p>8.3 – Système De Signalement Et De Modération Communautaire</p>
          <p>
            Devanco en tant qu'hébergeur n'est tenu à aucune obligation légale de surveillance du
            contenu transmis ou stocké via l'Application en vertu de l'article 6, I, 7° de la Loi
            pour la Confiance dans l'Economie Numérique du 21 juin 2004. Cependant, l'article 6-I-3°
            de cette même loi impose à Devanco, au titre de son statut d'hébergeur, de prévoir un
            système de notification afin de signaler les contenus illicites, abusifs et
            préjudiciables. Dans ce cas, après vérification du Service Client Devanco s'engage à
            retirer promptement ledit contenu. Au titre de l'article 6-I-5 de la LCEN : la
            connaissance d'un contenu litigieux est présumée acquise lorsqu'est notifié à
            l'hébergeur : la date de la notification l'identité de l'émetteur de ce contenu la
            description des faits litigieux (avec possibilité de joindre des captures d'écran des
            contenus litigieux) les motifs pour lesquels le contenu doit être retiré Devanco a donc
            doté l'Application d'un système de modération communautaire permettant aux Membres de
            signaler les contenus visés à l'article 6 I 7° de la LCEN s'ils constituent notamment
            l'apologie des crimes contre l'humanité, une provocation à la commission d'actes de
            terrorisme et leur apologie, l'incitation à la haine raciale, à la haine à l'égard de
            personnes à raison de leur sexe, de leur orientation ou identité sexuelle ou de leur
            handicap ainsi que notamment la pornographie enfantine, l'incitation à la violence,
            l'incitation aux violences faites aux femmes, ainsi que les atteintes à la dignité
            humaine. Guidées par une éthique professionnelle particulièrement pointilleuse à cet
            égard et refusant d'être associé tant à des Membres violents ou insultants qu'à des
            propos ou contenus illicites, abusifs et préjudiciables, les équipes de Devanco prennent
            cette procédure de signalement particulièrement au sérieux. C'est pourquoi, lorsque le
            contenu publié par un Membre fait l'objet d'un tel signalement, le Membre à l'origine de
            la publication de ce contenu est susceptible de voir son Compte suspendu / résilié, dans
            les conditions prévues à l'article 14.4 des présentes, sur la base des éléments de
            preuve fournis par l'émetteur du signalement et ce, afin d'assurer le retrait du contenu
            litigieux conformément aux obligations légales de Devanco. Par ailleurs, lorsqu'un
            utilisateur a un comportement suspect sur l'application (description ne respectant pas
            les CGU, nombreux signalements, photos non conformes aux CGU, mineur) Devanco se réserve
            le droit de désactiver le compte jusqu'à ce que l'équipe de Devanco analyse le compte en
            question. Le Membre dont les contenus publiés font l'objet d'un signalement sera informé
            de la suspension puis de la résiliation de son Compte via l'Application et/ou par e-mail
            à l'adresse qu'il a communiquée.Ces systèmes de signalement sont accessibles et
            opérationnels : Via la page contact sur le Site, via le bouton "signaler" sur
            l'historique des soirées auquel le membre a participé ; En cas de publication de contenu
            ayant fait l'objet d'une procédure de signalement et susceptible d'être qualifié
            d'infraction au regard des dispositions législatives et réglementaires en vigueur, le
            Membre victime de ce contenu est également invité à saisir les autorités de police
            compétentes. En effet, la procédure de signalement mise en place par Devanco ne remplace
            en rien une éventuelle plainte et procédure judiciaire à l'encontre du Membre à
            l'origine du contenu litigieux signalé. Tout abus du système de signalement (signalement
            infondé et effectué de mauvaise foi) sera également considéré comme une violation des
            présentes CGU et pourra entrainer la suspension / résiliation du Compte du Membre ayant
            commis un tel abus, conformément à l'article 14.4 des CGU, outre l'application de
            sanctions pénales conformément à l'article 6 I 4° de la LCEN et des poursuites
            judiciaires donnant lieu à l'indemnisation des victimes au titre de dommages et
            intérêts.
          </p>
          <p>8.4 – Règles De Prudence</p>
          <p>
            Le Membre est seul responsable de la vérification de l'identité des personnes avec qui
            il pourrait organiser une rencontre « réelle ». Devanco ne saurait être responsable en
            cas de problème de quelque nature que ce soit émanant d'une rencontre « réelle ».
          </p>
          <p>8.5 – Manifestation Collective</p>
          <p>
            Toute utilisation des Services Devanco dans le but d'organiser une réunion collective de
            ses Membres constitue un détournement du Service. Il est formellement interdit de
            planifier ou organiser une manifestation sur la voie publique au moyen de l'Application.
            Si une telle réunion avait lieu, la responsabilité de Devanco ne saurait être recherchée
            en cas de trouble à l'ordre public ou de quelconque dommage consécutif à de telles
            réunions.
          </p>
          <p>
            <strong>ARTICLE 9 : CODE DE CONDUITE DU MEMBRE</strong>
          </p>
          <p>9.1 – L'obligation De Loyauté Et D'honnêteté</p>
          <p>
            Lors De L'inscription Afin d'acquérir le statut de Membre, il est nécessaire de
            rassembler toutes les conditions suivantes: •Remplir les conditions de majorité telles
            que le prévoit la loi française (avoir 18 ans révolus) ; •Avoir rempli les informations
            sur son profil de manière sincère et véridique. Le Membre doit avoir communiqué le
            prénom, le sexe, la date de naissance qui lui sont attribués sur son état civil. A
            défaut, le Membre s'engage à corriger les erreurs sur son profil. Le Membre s'engage par
            la même occasion à fournir une adresse email valide. •S'engager à tenir régulièrement à
            jour les informations communiquées en accédant à son Compte depuis l'Application ;
            •Avoir pris connaissance des présentes CGU et les accepter en validant son inscription.
            Devanco n'a ni l'obligation légale ni les moyens techniques de vérifier l'identité et
            l'âge des Membres. Cependant en cas de doute, de présence d'éléments suspects ou sur
            dénonciation d'un tiers via la fonctionnalité « signalement » laissant présumer une
            usurpation d'identité ou d'informations fictives ou erronées, Devanco procédera à une
            enquête dans les plus brefs délais et pourra rectifier, le cas échéant, les informations
            communiquées sans dénaturer le profil du Membre. Pendant toute la durée de l'enquête,
            Devanco se réserve le droit de suspendre le Compte du Membre suspect et son accès à
            l'Application et d'effectuer les vérifications nécessaires, en particulier en lui
            demandant la copie d'une pièce d'identité officielle. Dans le cas où le Membre ne serait
            pas en mesure de communiquer ledit justificatif, ou en cas d'infraction avérée aux
            présentes CGU, Devanco pourra résilier le Compte du Membre mis en cause conformément aux
            conditions décrites à l'article 14.4 des présentes.
          </p>
          <p>9.2 L'obligation D'honnêteté Et De Loyauté Pendant L'utilisation</p>
          <p>
            Afin de demeurer Membre, il convient de respecter toutes les conditions suivantes : •
            être honnête et sincère dans ses déclarations (âge, sexe). • ne mettre en ligne aucune
            photo de tiers, ni aucune information confidentielle sur autrui telle que des données
            financières. • ne pas publier sur l'Application des contenus et photos pornographiques
            ou portant atteinte à la pudeur et à la dignité humaine. • respecter le caractère privé
            des contenus et des Messages échangés avec d'autres Membres au sein de l'Application et
            par conséquent de ne pas divulguer le contenu de ces Messages à des tiers. • ne pas
            enfreindre les lois sur la propriété intellectuelle. • ne pas troubler l'ordre public. •
            ne pas commettre de délit de diffamation et plus largement à ne pas tenir de propos
            injurieux en particulier à caractère racial ou discriminatoire. • respecter les lois et
            règlements en vigueur. • ne pas proférer d'incitation à la violence ou faire l'apologie
            du terrorisme, de crimes de guerre ou de crimes contre l'humanité. • ne pas enfreindre
            les règles de bienséance et à demeurer correct vis-à-vis des autres Membres. • ne porter
            atteinte, d'aucune manière que ce soit, au déroulement normal des Services ainsi qu'aux
            infrastructures de l'Application. • ne pas utiliser de robots ou d'outils autres que
            ceux mis à disposition par Devanco au sein de l'Application lors de la prise de contact
            avec d'autres. • ne pas utiliser l'Application pour organiser des services d'escort. •
            ne pas démarcher d'autres Membres dans le but de promouvoir des produits ou services
            payants. • ne pas divulguer à d'autres Membres ses informations de connexion, en
            particulier le mot de passe et l'identifiant de son Compte. • ne pas faire de publicité
            ou d'incitation de quelque ordre que ce soit à utiliser tout autre service, concurrent
            ou pas et à ne pas commettre de délit relevant de la concurrence déloyale. • ne pas
            utiliser l'Application dans le but d'organiser des rassemblements ou des manifestations.
            Le Membre s'interdit en outre d'effectuer toute copie et/ou diffusion, et/ou transfert
            de données techniques qu'il intercepterait sur le réseau en lien avec l'Application
            Devanco.
          </p>
          <p>9.3 Utilisation Personnelle</p>
          <p>
            Le Membre s'engage à utiliser les services uniquement à des fins personnelles. Le
            service ne doit être exploité ni à des fins commerciales, ni promotionnelles ni
            électorales, ni de recrutement. L'envoi de Messages à but marketing et/ou commercial
            et/ou de newsletters via la messagerie de l'Application qui incluraient, notamment et de
            manière non exhaustive, des contenus, indications ou liens hypertextes renvoyant à des
            sites ou supports de sociétés tierces, et notamment concurrentes de Devanco, est
            formellement prohibé.
          </p>
          <p>9.4 Responsabilité Du Membre</p>
          <p>
            Le Membre est seul responsable de ses actions et des données qu'il publie via
            l'Application. Si la responsabilité de Devanco venait à être recherchée dans le cadre du
            non-respect par le Membre de ses obligations, le Membre garantit Devanco contre toute
            condamnation prononcée à son encontre, trouvant son origine dans la transgression de la
            loi ou des présentes CGU par le Membre. Devanco n'exerce aucune modération des contenus
            publiés par les Membres et partagés via l'Application : en particulier, aucune photo,
            texte et contenu vers lesquels redirigent les liens hypertextes publiés par les Membres
            ne sont vérifiés par les équipes de Devanco. Le Membre pourra rapporter les contenus
            illicites, portant atteinte au droit français ou aux présentes CGU au moyen des outils
            de modération fournis par l'Application.
          </p>
          <p>9.5 Photos</p>
          <p>
            Toutes les photos à caractère pornographique, exhibitionniste portant atteinte à la
            pudeur et de manière générale contraires à la morale sont formellement prohibées. La
            publication de telles photos constitue une faute grave, pouvant donner lieu à la
            suspension et/ou à la résiliation du Compte du Membre, sans exclure d'autres sanctions
            qui pourraient être prononcées par la justice.
          </p>
          <p>9.6 Rencontres Réelles</p>
          <p>
            Devanco met en garde les Membres qui désirent effectuer des rencontres « réelles ». Il
            appartient entièrement aux Membres de vérifier l'identité des personnes avec qui ils
            organisent ces rencontres. Le déroulement de ces rencontres « réelles » est organisé
            indépendamment de Devanco, sous la seule responsabilité des Membres. La responsabilité
            de Devanco ne saurait être recherchée pour les actes et problèmes, quel qu'en soit la
            nature, commis ou provoqués par les Membres et/ou ancien Membre lors des évènements se
            déroulant suite à l'utilisation de l'Application. À cet égard, la messagerie Devanco
            présente volontairement l'avantage, à nouveau dans le but de préserver la tranquillité
            et la sécurité de ses Membres, de permettre aux Membres de décider des personnes qui
            seront autorisées à discuter avec eux. Concernant les personnes avec qui le Membre a
            accepté d'échanger des Messages, il est fortement recommandé de ne pas dévoiler les
            informations qui n'apparaissent pas sur le profil. Devanco conseille très vivement, par
            mesure de sécurité, d'organiser les rencontres réelles uniquement dans des lieux publics
            fortement fréquentés ou de prévenir un proche et de ne dévoiler ses coordonnées qu'après
            un délai raisonnable. L'application suggère aux membres de se retrouver devant un bar,
            restaurant ou autre lieu public, Devanco n'oblige en rien les membres à se rendre dans
            ces lieux ou à consommer quoi que ce soit En outre Devanco ne saurait être tenu
            responsable pour tout ce qui se déroule dans le cadre d'une rencontre
          </p>
          <p>9.7 Tenue Du Compte</p>
          <p>
            Le Membre doit consulter régulièrement sa messagerie et ses notifications par lesquelles
            Devanco le tiendra informé, entre autres, des informations relatives à ses Services et
            des modifications et mises à jour éventuellement apportées. Ces informations pourront
            également être envoyées par courriel. Le Membre peut accéder aux Services de son Compte
            en se connectant à l'Application au moyen d'un identifiant et d'un mot de passe
            confidentiels. Le Membre est seul responsable de ses informations de connexion et doit
            veiller à ce qu'aucun tiers ne puisse y accéder. En particulier, le Membre garantit
            Devanco de toute communication ou utilisation ou déclaration faite par l'intermédiaire
            de son Compte.
          </p>
          <p>9.8 Equipements</p>
          <p>
            Les équipements (matériels ou logiciels) nécessaires à l'accès aux Services ainsi que
            tous les autres frais afférents, y compris les frais de télécommunication sont à la
            charge exclusive du Membre. Le Membre est seul responsable du matériel utilisé dans le
            cadre du Service.
          </p>
          <p>
            <strong>ARTICLE 10 : COMMUNICATION AVEC LE MEMBRE</strong>
          </p>
          <p>10.1 – Notifications Push</p>
          <p>
            Le Membre pourra recevoir des notifications Push via l'Application afin d'être averti
            d'évènements importants parmi lesquels, et de manière non exhaustive : Une nouvelle
            soirée est proposée, un groupe a été affecté à l'utilisateur, un message de la part de
            l'Application ou un membre a été reçu, une invitation à noter une soirée. Le Membre
            pourra régler ses préférences en matière de notifications Push depuis son téléphone.
          </p>
          <p>10.2 Newsletter</p>
          <p>
            Lorsqu'il s'inscrit sur l'Application, le Membre consent à recevoir une newsletter ou
            des SMS à but publicitaire ou informatif de la part de Devanco ou ses partenaires. Il
            peut se rétracter à tout moment en exerçant son droit d'opposition par l'envoi d'un
            courriel à l'adresse communiquée à l'article 15.
          </p>
          <p>
            <strong>ARTICLE 11 : OBLIGATION DE SUPPRESSION DE COMPTES INACTIFS</strong>
          </p>
          <p>
            Depuis le 1er janvier 2017, Devanco procèdera à la suppression des Comptes inactifs,
            tels que définis ci-après Un Compte est considéré comme inactif si, pendant une période
            continue d'un an, : L'Application du Membre n'a pas établi de contact avec les serveurs
            de Devanco ; et Le Membre n'a pas utilisé l'Application Il est précisé que ces critères
            sont cumulatifs. Le Membre sera informé par e-mail, à l'adresse qu'il a renseignée dans
            l'Application (avec le Facebook connect) , de la suppression de son Compte moyennant un
            préavis raisonnable.
          </p>
          <p>
            <strong>ARTICLE 12 : PROPRIÉTÉ INTELLECTUELLE ET INDUSTRIELLE</strong>
          </p>
          <p>
            Les droits d'utilisation concédés par Devanco aux Membres sont réservés à un usage privé
            et personnel. Toute autre utilisation par le Membre est interdite sans l'autorisation de
            Devanco. Le Membre concède à Devanco et ses partenaires une licence gratuite, non
            exclusive, pour le monde entier et perpétuelle d'utilisation, reproduction,
            représentation, modification, traduction de tout élément siège de propriété
            intellectuelle (textes, photos, vidéos etc.) qu'il sera amené à fournir à Devanco via
            l'Application dans le but non exclusif de soutenir ses efforts de communication auprès
            des autres Membres. Cette licence sera exploitée par Devanco dans la limite du respect
            du droit à l'image, de la protection des données personnelles de l'utilisateur et ce
            sans dénaturer l'authenticité du profil du Membre et en demeurant conforme à son profil.
            Cette licence autorise Devanco à rendre ces éléments compatibles avec ses performances
            techniques ou les formats des supports concernés. Ces droits sont concédés pour le monde
            entier pour toute la durée d'exécution des présentes CGU entre le Membre et Devanco. Le
            Membre demeure propriétaire du contenu et des données personnelles qu'il fournit. Le
            Membre garantit Devanco contre toute action en justice, recours ou condamnation
            prononcée à son encontre trouvant son origine dans la violation par le Membre de la
            propriété intellectuelle d'un tiers. Le Membre garantit Devanco de toutes réclamations
            ou dommages qui pourraient en résulter.
          </p>
          <p>
            <strong>ARTICLE 13 : CONCURRENCE DÉLOYALE</strong>
          </p>
          <p>
            Il est formellement prohibé de concevoir un produit similaire à l'Application ou d'en
            créer une dérive, et plus largement de se rendre coupable de parasitisme, d'actes
            d'imitation, de dénigrement ou tout autre acte constitutif de concurrence déloyale. Le
            cas échéant, Devanco se réserve le droit d'assigner le Membre en vue d'obtenir
            réparation intégrale du préjudice que lui causé le Membre. Le Membre s'expose à de
            lourdes sanctions juridiques s'il réussit ou tente d'accorder des licences ou
            sous-licences, vendre, revendre, transférer, céder, distribuer ou exploiter
            commercialement d'une autre manière ou mettre à la disposition de tiers les Services ou
            l'Application d'une quelconque façon.
          </p>
          <p>
            <strong>ARTICLE 14 : FIN DU CONTRAT</strong>
          </p>
          <p>14.1 Déconnexion Du Compte Par Le Membre</p>
          <p>
            Le Membre peut tout le temps déconnecter son Compte sans préavis via l'Application s'il
            ne souhaite plus participer à de nouvelles soirées.
          </p>
          <p>14.2 Désinstallation De L'Application Par Le Membre</p>
          <p>
            Le Membre peut désinstaller l'Application de son mobile à tout moment sans préavis. Par
            conséquent, le Membre ne pourra plus avoir accès à l'Application à moins de la
            télécharger à nouveau. Lors de la désinstallation de l'application : Le membre ne sera
            plus invité à participer à de nouvelles soirées
          </p>
          <p>14.3 Suppression Du Compte Par Le Membre</p>
          <p>
            Le Membre peut décider de supprimer définitivement son Compte à tout moment dans
            l'application Devanco dans l'onglet Compte en bas à droite. Le membre peut également
            demander une suppression de compte par courrier dont l'adresse postale apparait à
            l'article 15. Cela à tout moment et sans préavis. Par conséquent le profil du Membre
            sera définitivement supprimé et l'ensemble de ses données également. Lorsque le Membre
            désire recommencer à utiliser l'Application, il est dans l'obligation de s'inscrire dans
            les mêmes conditions qu'une première inscription. Lors de la suppression du Compte : •
            Le profil du Membre sera rendu invisible aux autres Membres ; • Les données du Membre ne
            seront pas conservées, le membre perdra toutes les données concernant, l'historique des
            soirées, les conversations et toutes informations complémentaires. Le Membre ne peut pas
            réactiver son ancien Compte.
          </p>
          <p>
            14.4 – Suspension / Résiliation du Compte par Devanco en cas de manquement d'un Membre
            aux CGU
          </p>
          <p>
            En cas de manquement d'un Membre aux CGU, son Compte peut à tout moment être suspendu à
            titre conservatoire puis résilié de plein droit par Devanco. Devanco pourra suspendre le
            Compte du Membre pendant la durée nécessaire aux vérifications menées par le service
            client Devanco à la suite d'un signalement conformément à l'article 8.3 ou en cas de
            manquement présumé aux CGU susceptible d'être réparé. Le Membre dont le Compte est
            suspendu sera informé par Devanco de cette décision par l'envoi d'une notification sur
            l'Application et/ou par email. Le Membre est invité à prendre contact avec le service
            client de Devanco par e-mail afin d'obtenir de plus amples informations sur les motifs
            de la suspension de son compte, faire valoir ses observations éventuelles et/ou apporter
            la preuve que le manquement a été réparé. Si dans les 30 jours de la notification de
            suspension du Compte, le Membre n'a pas contacté le service client de Devanco ou si les
            éléments fournis par le Membre au service client n'ont pas permis de réparer le
            manquement, Devanco pourra notifier au Membre la résiliation de son Compte via
            l'Application et/ou par email et informer le Membre de la durée pendant laquelle il ne
            pourra se réinscrire à l'Application. A défaut de précision, cette durée est fixée à 3
            ans à compter de la notification de la résiliation du Compte. Lors de la résiliation du
            Compte : • Le profil du Membre sera rendu invisible aux autres Membres ; • Le Membre ne
            pourra pas réouvrir de Compte pendant une durée maximale de 3 ans à compter de la
            notification de la résiliation du Compte.
          </p>
          <p>
            <strong>ARTICLE 15 : CONTACT</strong>
          </p>
          <p>
            Devanco, SARL au capital de 150 000€ immatriculée 537862013 au RCS de Toulouse peut être
            contactée par courrier 93 AV DES MINIMES 31200 TOULOUSE Le Membre peut aussi contacter
            Devanco par courrier électronique à l'adresse suivante : contact@devanco.com
          </p>
          <p>
            <strong>ARTICLE 16 : CLAUSE DE CONFIDENTIALITÉ</strong>
          </p>
          <p>
            Ni Devanco ni le Membre ne sont autorisés à divulguer des informations sur le contrat
            les liant en cas de litige et chacune des parties s'engage à respecter une clause de
            confidentialité qui l'oblige à préférer une négociation confidentielle en cas de
            différend.
          </p>
          <p>Dernière mise à jour : le 05/05/2021</p>
        </div>
      </main>
    </div>
  )
}

export default PageCgu
