import React from 'react'
import { useLocation } from 'react-router'
import { useFetchApi } from '../hook'
import { qps_from_search } from '../misc'

const PagePasswordRecovery = () => {
  const [pwd, set_pwd] = React.useState('')
  const [pwd2, set_pwd2] = React.useState('')
  const [submit_state, set_submit_state] = React.useState(null)
  const { search } = useLocation()
  const { token } = qps_from_search(search)

  React.useEffect(() => {
    window.$crisp.push(['do', 'chat:hide'])
  })

  const fetcher = useFetchApi()

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault()

      if (pwd !== pwd2) {
        window.alert('Les 2 mots de passe ne sont pas identiques')
      } else {
        set_submit_state('submitting')

        try {
          await fetcher('/user/password', {
            method: 'PUT',
            body: JSON.stringify({ password: pwd, token })
          })
          set_submit_state('submitted')
        } catch (err) {
          window.alert('Oops ! Le lien ne semble plus être valide')
          set_submit_state(null)
        }
      }
    },
    [fetcher, pwd, pwd2, token]
  )

  return (
    <>
      <header style={{ height: '100%' }}>
        <div className="layer" style={{ height: '100%' }}>
          <div className="container">
            <div className="headerlogo">
              <img src="images/logo.svg" alt="Logo Mardi Soir Je Sors"></img>
            </div>

            <h1>Changement de mot de passe</h1>

            {submit_state !== 'submitted' && <p>Définis un nouveau mot de passe</p>}

            {submit_state === 'submitted' ? (
              <p>Tu peux désormais te connecter avec ce nouveau mot de passe</p>
            ) : (
              <form onSubmit={onSubmit}>
                <input
                  type="password"
                  style={{ padding: 10, marginTop: 50, width: '100%', fontSize: 19 }}
                  placeholder="mot de passe"
                  required
                  value={pwd}
                  onChange={(e) => set_pwd(e.target.value)}
                />
                <input
                  type="password"
                  style={{ padding: 10, marginTop: 20, width: '100%', fontSize: 19 }}
                  placeholder="répéter mot de passe"
                  required
                  value={pwd2}
                  onChange={(e) => set_pwd2(e.target.value)}
                />

                <div style={{ marginTop: 30 }}>
                  <input
                    disabled={submit_state !== null}
                    type="submit"
                    style={{
                      width: '100%',
                      border: 'none',
                      padding: 20,
                      background: '#FFF06C',
                      color: '#495057',
                      fontWeight: 700,
                      opacity: submit_state === 'submitting' ? 0.4 : 1
                    }}
                    value="Valider"
                  />
                </div>
              </form>
            )}
          </div>
        </div>
      </header>
    </>
  )
}

export default PagePasswordRecovery
