import React from 'react'
import { useLocation } from 'react-router'
import { qps_from_search } from '../misc'

const PageHome = () => {
  const { search } = useLocation()
  const { contact } = qps_from_search(search)

  React.useEffect(() => {
    if (contact && window) {
      window.$crisp.push(['do', 'chat:open'])
    }
  }, [contact])

  return (
    <>
      <header>
        <div className="layer">
          <div className="container">
            <div className="headerlogo">
              <img src="images/logo.svg" alt="Logo Mardi Soir Je Sors"></img>
            </div>

            <h1>De nouveaux amis dans ta ville !</h1>

            <p>
              Autour d'une activité, rencontre des nouvelles personnes et partage des moments
              inoubliables !
            </p>

            <div className="stores">
              <div className="appstore">
                <a
                  href="https://apps.apple.com/us/app/mardi-soir-je-sors/id1568938053"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="images/appstore.svg" alt="Téléchargez dans l'App Store"></img>
                </a>
              </div>

              <div className="googleplay">
                <a
                  href="https://play.google.com/store/apps/details?id=com.devanco.msjs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="images/googleplay.svg" alt="Disponible sur Google Play"></img>
                </a>
              </div>
            </div>
          </div>
        </div>{' '}
        {/* closing layer */}
      </header>

      <main>
        <section className="section1">
          <div className="container">
            <h2>Comment ça marche ?</h2>

            <article className="days">
              <div className="sunday">
                <img src="images/icon-calendar.svg" alt="Calendrier" />

                <p>Tu t'inscris aux activités proposées sur l'appli, seul ou à plusieurs.</p>
              </div>

              <div className="monday">
                <img height={100} src="images/icon-location.svg" alt="GPS"></img>

                <p>
                  Tu connais le lieu et l'heure de RDV ainsi que les prénoms des 3-4 personnes du
                  groupe avec qui tu vas faire l'activité.
                </p>
              </div>

              <div className="tuesday">
                <img src="images/icon-party.svg" alt="Fête"></img>

                <p>
                  C'est le jour J ! Il ne te reste plus qu'à préparer tes meilleures blagues, ton
                  plus beau sourire et à profiter au maximum !
                </p>
              </div>
            </article>
          </div>
        </section>

        <section className="section2">
          <div className="container">
            <article className="article1">
              <div className="text">
                <h3>Ose la rencontre</h3>
                <p>
                  Tu viens d’arriver sur Bordeaux et ne connais encore personne ? Ou bien tu veux
                  simplement élargir ton cercle social ? Alors bienvenue à toi ! Avec Mardi Soir Je
                  Sors, viens rencontrer de nouvelles personnes chaque mardi (et d'autres jours
                  aussi, mais faut qu'on soit un peu cohérent avec le nom de notre appli !) pour
                  partager un moment de convivialité et te sentir moins seul(e) dans la jungle
                  bordelaise.
                </p>
              </div>
              <img src="images/drinks.svg" alt="Trinquer" className="desktopimg"></img>
            </article>

            <article className="article2">
              <img src="images/3people.svg" alt="Groupe d'amis" className="desktopimg"></img>
              <div className="text">
                <h3>Sors de ta zone de confort</h3>
                <p>
                  Fini les soirées moroses devant Netflix à te lamenter sur ta vie sociale proche du
                  néant. Deviens acteur de ta vie en découvrant de nouveaux lieux tout en
                  rencontrant de nouvelles personnes ! Tu nous remercieras quand ton répertoire sera
                  plein ;)
                </p>
              </div>
            </article>

            <article className="article3">
              <div className="text">
                <h3>Viens en duo !</h3>
                <p>
                  Tu doutes encore ? Tu as peur de sauter le pas en solo ? Parce qu’on sait que la
                  première fois peut être intimidante, tu peux préciser que tu viens accompagné(e).
                  Tu n’as ainsi plus d’excuses, alors n’hésite plus, et rejoins nous !
                </p>
              </div>
              <img src="images/2people.svg" alt="Deux amis" className="desktopimg"></img>
            </article>
          </div>

          <img src="images/drinks-mobile.svg" alt="Trinquer" className="mobileimg drinks"></img>
        </section>

        <section className="section3">
          <div className="container">
            <h2>Où ça se passe ?</h2>

            <div className="text" style={{ marginBottom: 50 }}>
              <p>
                Pour l'instant Mardi Soir je Sors n'est disponible que dans la ville où il y a gavé
                de chocolatines à savoir <strong>Bordeaux</strong> ! Mais Nous allons bien entendu
                ouvrir très rapidement de nouvelles villes ! N'hésite pas à nous contacter en nous
                donnant ta ville ! Les lieux de rendez-vous se situeront toujours dans le
                centre-ville (carte très approximative 😜 )
              </p>
            </div>
          </div>

          <img
            src="images/04a4761ad13af7d5b016c6871fdab4a2.png"
            alt="map"
            style={{
              height: '70vh',
              objectFit: 'cover',
              minWidth: '100%',
              objectPosition: 'center'
            }}
          />
        </section>

        <section className="section4">
          <div className="container">
            <div className="text">
              <h2>Participe à la prochaine soirée !</h2>

              <p>
                Marre de swiper alors que tu veux simplement rencontrer de nouvelles personnes sans
                te prendre la tête ? Avec Mardi Soir Je Sors, rencontre de vraies personnes, dans la
                vraie vie en t'inscrivant dès maintenant !
              </p>

              <div className="stores">
                <div className="appstore">
                  <a
                    href="https://apps.apple.com/us/app/mardi-soir-je-sors/id1568938053"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="images/appstore.svg" alt="Téléchargez dans l'App Store"></img>
                  </a>
                </div>

                <div className="googleplay">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.devanco.msjs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="images/googleplay.svg" alt="Disponible sur Google Play"></img>
                  </a>
                </div>
              </div>
            </div>

            <div className="smartphone">
              <img
                src="images/smartphone.svg"
                alt="Ecran smartphone"
                className="smartphoneimg desktopimg"
              ></img>
              <img
                src="images/screenshot.jpg"
                alt="Ecran smartphone"
                className="desktopimg"
                style={{
                  transform: 'rotate(15deg)',
                  maxWidth: 325,
                  position: 'absolute',
                  right: -73,
                  borderRadius: 20,
                  bottom: -333
                }}
              ></img>
            </div>
          </div>
        </section>
      </main>

      <footer>
        <div className="container">
          <div className="logo-copyright">
            <div className="footerlogo">
              <img src="images/footer-logo.svg" alt="Logo Mardi Soir Je Sors"></img>
            </div>

            <p>
              <span className="line">© 2020 Mardi Soir Je sors</span>{' '}
              <span className="hide">-</span>{' '}
              <span className="line">Images from Freepik and Vecteezy</span>
              <span className="hide"> - </span>{' '}
              <a style={{ textDecoration: 'underline' }} href="/policy">
                Politique de confidentialité
              </a>
              <span className="hide"> - </span>{' '}
              <a style={{ textDecoration: 'underline' }} href="/cgu">
                CGU
              </a>
            </p>
          </div>

          <div className="contact-social">
            <div className="contact">
              <a href="/?contact=1">
                <p>Nous contacter</p>
              </a>
            </div>

            {/* <div className="social">
              <a href="https://www.facebook.com/appmsjs">
                <img src="images/fb.svg" alt="Facebook" className="facebook" />
              </a>
              <a href="#">
                <img src="images/instagram.svg" alt="Instagram" className="instagram" />
              </a>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  )
}

export default PageHome
