import React from 'react'

export class FetchError extends Error {
  constructor({ message, status }) {
    super(`(${status}) ${message}`)
    this.status = status
    this.name = 'FetchError'
    this.errorMessage = message
  }
}

export const useFetchApi = () => {
  const fetcher = React.useCallback(async (url, options) => {
    const headers = (options && options.headers) || { 'Content-Type': 'application/json' }

    headers['x-msjs-platform'] = 'web'

    let response = null
    response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}${url}`, {
      ...options,
      headers
    })

    const { ok, status } = response

    if (status === 204) return 'no content'

    if (ok) return response.json()

    let message = 'no json message'
    try {
      const json = await response.json()
      message = json.message
    } catch (err) {}

    throw new FetchError({ status, message })
  }, [])

  return fetcher
}
